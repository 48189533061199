/* eslint-disable @typescript-eslint/naming-convention */
export const STORAGE_KEY = 'CD_TAY_DO_APP';
export const STORAGE_KEY_ID = STORAGE_KEY + '_id';
export const STORAGE_KEY_NAME = STORAGE_KEY + '_name';
export const STORAGE_KEY_ROLE = STORAGE_KEY + '_role';
export const STORAGE_KEY_TOKEN = STORAGE_KEY + '_token';
export const STORAGE_KEY_USERNAME = STORAGE_KEY + '_username';
export const STORAGE_KEY_PUSH = STORAGE_KEY + '_push';

export const minWidth = 576;
export const clientWidth = 1200;
export const sideBarWidthClose = 50;
export const sideBarWidthOpen = 200;
export const defaultPageSize = 20;
