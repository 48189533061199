import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ErrorComponent } from '../../component/error/error.component';
import { ModalController } from '@ionic/angular';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router, public modalController: ModalController
  ) { }

  showError(error: HttpErrorResponse) {
    this.modalController.getTop().then(async isModal => {
      if (!isModal) {
        const modal = await this.modalController.create({
          component: ErrorComponent,
          componentProps: { title: error.error.errors.exceptionMessage }
        });
        await modal.present();
      }
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.body.errors) {
          this.showError({ error: event.body } as HttpErrorResponse);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.router.navigateByUrl('/user/login');
          return throwError(() => { });
        } else if (error.status === 400) {
          this.showError(error);
          return throwError(error);
        } else {
          this.showError(error);
          return throwError(error);
        }
      })
    );
  }
}
